
import { dateFrancaise, variables } from "@/plugins/variables"
import { Utilisateur } from "@/store/types"
import axios from "axios"
import Vue from "vue"
import Saisirdate from "../components/SaisirDate.vue"
import NonAcces from "../components/NonAcces.vue"

export default Vue.extend({
  name: "Salaries",
  components: { Saisirdate, NonAcces },
  data() {
    return {
      unsubscribe: null as any,
      utilisateurs: [] as Utilisateur[],
      utilisateur: new Utilisateur(),
      utilisateurTampon: new Utilisateur(),
      dialogueNouveauUtilisateur: false,
      langueDisponible: [] as any[],
      dictionnaire: [] as string[],
      snakbar_text: "",
      snak_visible: false
    }
  },
  methods: {
    initialiser() {
      axios
        .get(this.$store.state.ajaxurl + "Salaries.php", {
          params: {
            action: "INITIALISER_SALARIER"
          }
        })
        .then(response => {
          this.utilisateurs = response.data.utilisateurs.map((u: Utilisateur) => {
            return new Utilisateur(u)
          })
          this.utilisateurs.splice(
            this.utilisateurs.findIndex((u: Utilisateur) => u.ut_id == 0),
            1
          )
          this.choixLangue(this.$store.state.langue)
        })
    },
    ouvrirNouveauUtilisateur() {
      this.utilisateur = new Utilisateur()
      this.dialogueNouveauUtilisateur = true
    },
    fermerNouvelUtilisateur() {
      this.dialogueNouveauUtilisateur = false
      if (this.utilisateurTampon.ut_id != -1) {
        const indexUtilisateur = this.utilisateurs.findIndex((u: Utilisateur) => u.ut_id == this.utilisateurTampon.ut_id)
        if (indexUtilisateur != -1) {
          this.utilisateurs[indexUtilisateur] = this.utilisateurTampon
          this.utilisateurTampon = new Utilisateur()
        }
      }
    },
    selectionnerUtilisateur(unUtilisateur: Utilisateur) {
      this.utilisateur = unUtilisateur
    },
    modifierUtilisateur(unUtilisateur: Utilisateur) {
      this.utilisateur = unUtilisateur
      this.utilisateurTampon = new Utilisateur(unUtilisateur)
      this.dialogueNouveauUtilisateur = true
    },
    enregistrerUtilisateur() {
      axios
        .post(this.$store.state.ajaxurl + "Salaries.php", {
          action: "ENREGISTRER_SALARIER",
          utilisateur: this.utilisateur
        })
        .then(() => {
          this.utilisateurTampon = new Utilisateur()
          this.dialogueNouveauUtilisateur = false
          this.initialiser()
        })
    },
    snackbarVisible(text: string) {
      this.snakbar_text = text
      this.snak_visible = true
    },
    dateFrancaise(date: string) {
      if (date == "" || date == null) {
        return ""
      }
      return dateFrancaise(date)
    },
    choixLangue(langue: string) {
      switch (langue) {
        case "FR":
          this.dictionnaire = [
            "Nouveau", //0
            "Matricule", //1
            "Nom", //2
            "Prénom", //3
            "Société", //4
            "Langue", //5
            "Identifiant", //6
            "Mot de passe", //7
            "Mot de passe application", //8
            "Date entrée", //9
            "Date sortie", //10
            "Date visite médical", //11
            "Nouvel utilisateur", //12
            "Enregistrer" //13
          ]
          this.langueDisponible = [
            { value: "AL", text: "Allemand" },
            { value: "FR", text: "Français" }
          ]
          break
        case "AL":
          this.dictionnaire = [
            "Neu", //0
            "Personalnummer", //1
            "Name", //2
            "Vorname", //3
            "Unternehmen", //4
            "Sprache", //5
            "Benutzername", //6
            "Passwort", //7
            "App-Passwort", //8
            "Eingang Datum", //9
            "Ausgang Datum", //10
            "Medizinischer Besuch datum", //11
            "Neuer Benutzer", //12
            "Registrieren" //13
          ]
          this.langueDisponible = [
            { value: "AL", text: "Deutsch" },
            { value: "FR", text: "Französisch" }
          ]
          break
      }
    },
    autAdministration() {
      return variables.autorisation.includes("Administration")
    }
  },
  created() {
    this.initialiser()
    this.choixLangue(this.$store.state.langue)
    this.unsubscribe = this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case "majLangue":
          this.choixLangue(this.$store.state.langue)
      }
    })
  },
  beforeDestroy() {
    this.unsubscribe = null
  }
})
